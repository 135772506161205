/**
 * This module patches the /api/connectors endpoint
 * by adding the workload migration state to the returned data
 */
import { rest } from 'msw';
import connectorsFixtures from '../fixtures/connector-data.json';

const workload = 'workload';
// Requests the actual API Data
const getAPIResponseData = async (req, ctx) => {
  const apiResponse = await ctx.fetch(req);
  return apiResponse.json();
};

// Patches the API response with the services update state.
const getConnectorsMigrationState = () => {
  const c1wsMigrationState = localStorage.getItem('C1C_C1WS_UPDATE_STATE');
  if (!['migrated', 'migrating', 'failed'].includes(c1wsMigrationState)) {
    return '';
  }

  return connectorsFixtures[c1wsMigrationState];
};

const getConnectorStatus = rest.get('**/api/connectors', async (req, res, ctx) => {
  const { registration, services = [] } = await getAPIResponseData(req, ctx);

  const nextServices = [...services];
  const workloadServiceIndex = nextServices.findIndex(({ name }) => name === workload);
  if (workloadServiceIndex === -1) {
    nextServices.push({ name: workload, status: getConnectorsMigrationState() });
  } else {
    nextServices[workloadServiceIndex] = { name: workload, status: getConnectorsMigrationState() };
  }

  return res(
    ctx.status(200),
    ctx.json({
      registration,
      services: nextServices,
    })
  );
});

const postProductInstanceToken = rest.get('**/api/connectors', async (req, res, ctx) => {
  const c1wsMigrationState = localStorage.getItem('C1C_C1WS_UPDATE_STATE');
  if (!['migrated', 'migrating', 'failed'].includes(c1wsMigrationState)) {
    return {};
  }

  return res(
    ctx.status(200),
    ctx.json({
      ...apiResponseData,
    })
  );
});

export default [getConnectorStatus];
