import { setupWorker } from 'msw';
import { handlers } from './mock-handlers';
import {
  C1C_MOCK_API_PAYLOAD_TOKEN,
  C1C_MOCK_API_PAYLOAD_SERVICES,
  C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL,
  C1C_MOCK_API_PAYLOAD_ACCOUNT,
  C1C_MOCK_API_PAYLOAD_ACCOUNTS,
  C1C_MOCK_API_PAYLOAD_USERS,
  C1C_MOCK_API_PAYLOAD_USERS_2,
  C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT,
  C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2,
  C1C_MOCK_API_PAYLOAD_ROLES,
  C1C_MOCK_API_CURSOR,
} from './mock-data';
import v1esUpdateHandlers from './rainbow-mocks/handlers';

export const resetApiMock = () => {
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_TOKEN');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_SERVICES');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ACCOUNT');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ACCOUNTS');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_USERS');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_USERS_2');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2');
  localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ROLES');
  localStorage.removeItem('C1C_MOCK_API_CURSOR');
};

export const configureV1ESAPIMocks = async (enabled = false) => {
  const worker = setupWorker(...v1esUpdateHandlers);
  worker.stop();
  if (enabled) {
    await worker.start({ onUnhandledRequest: 'bypass' });
  }
};

export const toggleApiMock = async (hasEnableApiMock) => {
  // This configures a Service Worker with the given request handlers
  const worker = setupWorker(...handlers);
  if (hasEnableApiMock) {
    console.debug(
      '%c [msw] 🚀 ~ Initializing!',
      'font-weight: bold; background-color:aqua; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
    // Initialize the mock values in localStorage if it does not exist
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_TOKEN')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_TOKEN',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_TOKEN)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_SERVICES')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_SERVICES',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_SERVICES)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNT')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_ACCOUNT',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_ACCOUNT)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNTS')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_ACCOUNTS',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_ACCOUNTS)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_USERS')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_USERS',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_USERS)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_USERS_2')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_USERS_2',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_USERS_2)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_PAYLOAD_ROLES')) {
      localStorage.setItem(
        'C1C_MOCK_API_PAYLOAD_ROLES',
        JSON.stringify(C1C_MOCK_API_PAYLOAD_ROLES)
      );
    }
    if (!localStorage.getItem('C1C_MOCK_API_CURSOR')) {
      localStorage.setItem('C1C_MOCK_API_CURSOR', JSON.stringify(C1C_MOCK_API_CURSOR));
    }
    await worker.start({ onUnhandledRequest: 'bypass' });
    console.debug(
      '%c [msw] 🚀 ~ Print list of APIs that will be mocked',
      'font-weight: bold; background-color:aqua; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
    worker.printHandlers();
  } else {
    worker.stop();
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_TOKEN');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_SERVICES');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ACCOUNT');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ACCOUNTS');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_USERS');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_USERS_2');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2');
    localStorage.removeItem('C1C_MOCK_API_PAYLOAD_ROLES');
    localStorage.removeItem('C1C_MOCK_API_CURSOR');
    console.debug(
      '%c [msw] 🚀 ~ Mocking has stopped. LocalStorage mocked payload cleared.',
      'font-weight: bold; background-color:aqua; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
  }
};
