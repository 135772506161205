import { rest } from 'msw';

import {
  C1C_MOCK_API_PAYLOAD_TOKEN,
  C1C_MOCK_API_PAYLOAD_SERVICES,
  C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL,
  C1C_MOCK_API_PAYLOAD_ACCOUNT,
  C1C_MOCK_API_PAYLOAD_ACCOUNTS,
  C1C_MOCK_API_PAYLOAD_USERS,
  C1C_MOCK_API_PAYLOAD_USERS_2,
  C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT,
  C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2,
  C1C_MOCK_API_PAYLOAD_ROLES,
  C1C_MOCK_API_CURSOR,
} from './mock-data';

const isSSOv2LoggedIn = () => {
  // Check if the user is authenticated for SSOv2 session
  const c1cShellState = sessionStorage.getItem('C1C_Shell_State');
  if (c1cShellState === null) {
    return false;
  }
  return JSON.parse(c1cShellState)?.c1Common?.loggedIn ?? false;
};

const mockToken = (req, res, ctx) => {
  console.group();
  console.debug(
    '%c [msw] 🚀 ~ mock-handlers Token Service API call intercepted!',
    'font-weight: bold; background-color:orangered; color:white; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
  );
  console.groupEnd();
  // Check if the user is authenticated in this session
  const ssoV1_isLoggedIn = sessionStorage.getItem('isWorkloadSignedIn');
  if (!ssoV1_isLoggedIn && !isSSOv2LoggedIn()) {
    // If not authenticated, respond with a 403 error
    return res(
      ctx.status(403),
      ctx.json({
        message: 'Not authorized',
      })
    );
  }
  // If authenticated, return a mocked payload
  const { c1AccountId } = req.params;
  const mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_TOKEN')
    ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_TOKEN'))
    : C1C_MOCK_API_PAYLOAD_TOKEN;
  console.group();
  console.debug(
    '%c [msw] 🚀 ~ mock-handlers c1AccountId:',
    'font-weight: bold; background-color:orangered; color:white; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;',
    c1AccountId
  );
  console.groupEnd();
  return res(
    // Respond with a status code
    ctx.status(201),
    ctx.json(mockPayload)
  );
};

const mockServiceInventory = (req, res, ctx) => {
  console.group();
  console.debug(
    '%c [msw] 🚀 ~ mock-handlers Service Inventory API call intercepted!',
    'font-weight: bold; background-color:orangered; color:white; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
  );
  console.groupEnd();
  // Check if the user is authenticated in this session
  const ssoV1_isLoggedIn = sessionStorage.getItem('isWorkloadSignedIn');
  if (!ssoV1_isLoggedIn && !isSSOv2LoggedIn()) {
    // If not authenticated, respond with a 403 error
    return res(
      ctx.status(403),
      ctx.json({
        message: 'Not authorized',
      })
    );
  }
  // If authenticated, return a mocked payload
  const mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_SERVICES')
    ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_SERVICES'))
    : C1C_MOCK_API_PAYLOAD_SERVICES;
  return res(ctx.status(200), ctx.json(mockPayload));
};

const mockAccounts = (req, res, ctx) => {
  //use relative path for API call routed to the same origin
  console.group();
  console.debug(
    '%c [msw] 🚀 ~ mock-handlers Accounts API call intercepted!',
    'font-weight: bold;background-color:mediumspringgreen; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
  );
  console.groupEnd();
  // Check if the user is authenticated in this session
  if (!isSSOv2LoggedIn()) {
    // If not authenticated, respond with a 403 error
    return res(
      ctx.status(403),
      ctx.json({
        message: 'Not authorized',
      })
    );
  }
  // If authenticated, return a mocked payload
  const mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNTS')
    ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNTS'))
    : C1C_MOCK_API_PAYLOAD_ACCOUNTS;
  return res(ctx.status(200), ctx.json(mockPayload));
};

// Cross origin API calls needs to begin with 'https://domain' and domain specified, instead of using relative path to the origin domain
export const handlers = [
  rest.post(
    'https://accounts.us-1.dev-cloudone.trendmicro.com/api/accounts/:c1AccountId/tokens',
    mockToken
  ),
  rest.post(
    'https://accounts-ca-1.ssov1.dev-cloudone.trendmicro.com/api/accounts/:c1AccountId/tokens',
    mockToken
  ),
  rest.get('https://services.us-1.dev-cloudone.trendmicro.com/api/services', mockServiceInventory),
  rest.get('https://services.ca-1.dev-cloudone.trendmicro.com/api/services', mockServiceInventory),
  rest.get('https://services.eu-1.dev-cloudone.trendmicro.com/api/services', mockServiceInventory),
  rest.get('https://services.ca-1.cloudone.trendmicro.com/api/services/', mockServiceInventory),
  rest.get(
    'https://13sfg1lmxg.execute-api.us-east-1.amazonaws.com/dev-testing-ssx/api/services',
    mockServiceInventory
  ),
  rest.get('/api/accountdetails/current', (req, res, ctx) => {
    //use relative path for API call routed to the same origin
    console.group();
    console.debug(
      '%c [msw] 🚀 ~ mock-handlers Account Detail Type API call intercepted!',
      'font-weight: bold;background-color:mediumspringgreen; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
    console.groupEnd();
    // Check if the user is authenticated in this session
    const ssoV1_isLoggedIn = sessionStorage.getItem('isWorkloadSignedIn');
    if (!ssoV1_isLoggedIn && !isSSOv2LoggedIn()) {
      // If not authenticated, respond with a 403 error
      return res(
        ctx.status(403),
        ctx.json({
          message: 'Not authorized',
        })
      );
    }
    // If authenticated, return a mocked payload
    const mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL')
      ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL'))
      : C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL;
    return res(ctx.status(200), ctx.json(mockPayload));
  }),
  rest.get(
    'https://accounts.dev-cloudone.trendmicro.com/api/accounts/:c1AccountId',
    (req, res, ctx) => {
      //use relative path for API call routed to the same origin
      console.group();
      console.debug(
        '%c [msw] 🚀 ~ mock-handlers Account API call intercepted!',
        'font-weight: bold;background-color:mediumspringgreen; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
      );
      console.groupEnd();
      // Check if the user is authenticated in this session
      if (!isSSOv2LoggedIn()) {
        // If not authenticated, respond with a 403 error
        return res(
          ctx.status(403),
          ctx.json({
            message: 'Not authorized',
          })
        );
      }

      // If authenticated, return a mocked payload
      let mockPayload = {};
      const { c1AccountId } = req.params;
      mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNT')
        ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_ACCOUNT'))
        : C1C_MOCK_API_PAYLOAD_ACCOUNT;

      const foundAccount = mockPayload.account.find((accound) => accound.id === c1AccountId);

      return res(
        // Respond with a status code
        ctx.status(201),
        ctx.json(foundAccount)
      );
    }
  ),
  rest.get('https://accounts.dev-cloudone.trendmicro.com/api/accounts', mockAccounts),
  rest.get('https://accounts.dev-cloudone.trendmicro.com/api/users', (req, res, ctx) => {
    //use relative path for API call routed to the same origin
    console.group();
    console.debug(
      '%c [msw] 🚀 ~ mock-handlers Users API call intercepted!',
      'font-weight: bold;background-color:mediumspringgreen; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
    console.groupEnd();
    // Check if the user is authenticated in this session
    if (!isSSOv2LoggedIn()) {
      // If not authenticated, respond with a 403 error
      return res(
        ctx.status(403),
        ctx.json({
          message: 'Not authorized',
        })
      );
    }
    // If authenticated, return a mocked payload
    let mockPayload = {};
    //If cursor present, return different mocked payload
    const cursor = req.url.searchParams.get('cursor').split(';')[0];
    if (cursor === C1C_MOCK_API_CURSOR) {
      mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_USERS_2')
        ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_USERS_2'))
        : C1C_MOCK_API_PAYLOAD_USERS_2;
    } else {
      mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_USERS')
        ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_USERS'))
        : C1C_MOCK_API_PAYLOAD_USERS;
    }
    return res(ctx.status(200), ctx.json(mockPayload));
  }),
  rest.get('https://accounts.dev-cloudone.trendmicro.com/api/invitations/sent', (req, res, ctx) => {
    //use relative path for API call routed to the same origin
    console.group();
    console.debug(
      '%c [msw] 🚀 ~ mock-handlers Invitations Sent API call intercepted!',
      'font-weight: bold;background-color:mediumspringgreen; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
    console.groupEnd();
    // Check if the user is authenticated in this session
    if (!isSSOv2LoggedIn()) {
      // If not authenticated, respond with a 403 error
      return res(
        ctx.status(403),
        ctx.json({
          message: 'Not authorized',
        })
      );
    }
    // If authenticated, return a mocked payload
    let mockPayload = {};
    //If cursor present, return different mocked payload
    const cursor = req.url.searchParams.get('cursor').split(';')[0];
    if (cursor === C1C_MOCK_API_CURSOR) {
      mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2')
        ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2'))
        : C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2;
    } else {
      mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT')
        ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT'))
        : C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT;
    }
    return res(ctx.status(200), ctx.json(mockPayload));
  }),
  rest.get('https://accounts..dev-cloudone.trendmicro.com/api/roles', (req, res, ctx) => {
    //use relative path for API call routed to the same origin
    console.group();
    console.debug(
      '%c [msw] 🚀 ~ mock-handlers Roles API call intercepted!',
      'font-weight: bold;background-color:mediumspringgreen; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;'
    );
    console.groupEnd();
    // Check if the user is authenticated in this session
    if (!isSSOv2LoggedIn()) {
      // If not authenticated, respond with a 403 error
      return res(
        ctx.status(403),
        ctx.json({
          message: 'Not authorized',
        })
      );
    }
    // If authenticated, return a mocked payload
    const mockPayload = localStorage.getItem('C1C_MOCK_API_PAYLOAD_ROLES')
      ? JSON.parse(localStorage.getItem('C1C_MOCK_API_PAYLOAD_ROLES'))
      : C1C_MOCK_API_PAYLOAD_ROLES;
    return res(ctx.status(200), ctx.json(mockPayload));
  }),
];
