export async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder('utf-8').encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

export const delay = (time) => {
  return new Promise((resolve, reject) => {
    if (isNaN(time)) {
      return reject(new Error('Parameter must be a number'));
    }
    setTimeout(resolve, time);
  });
};

// TODO: Remove the FeatureFlag check with C1C-11968
const checkGovCloudRegion = () => {
  const isGovCloudDeploymentTarget = ['govcloud-prod', 'govcloud-staging'].includes(
    DEPLOYMENT_TARGET
  );
  const hasGovCloudFeatureFlag = JSON.parse(
    localStorage.getItem('C1C_GOV_CLOUD_ENABLED') || 'false'
  );
  return isGovCloudDeploymentTarget || hasGovCloudFeatureFlag;
};
export const isGovCloudRegion = checkGovCloudRegion();
const checkAlpha = () => {
  const isAlphaDeploymentTarget = ['alpha'].includes(DEPLOYMENT_TARGET);
  return isAlphaDeploymentTarget;
};
export const isAlphaRegion = checkAlpha();
const checkDev = () => {
  const isDevDeploymentTarget = ['dev'].includes(DEPLOYMENT_TARGET);
  return isDevDeploymentTarget;
};
export const isDevRegion = checkDev();
const checkStaging = () => {
  const isStagingDeploymentTarget = ['stage'].includes(DEPLOYMENT_TARGET);
  return isStagingDeploymentTarget;
};
export const isStagingRegion = checkStaging();

export const hasProdLogsEnabled = JSON.parse(
  localStorage.getItem('C1C_PROD_LOGS_ENABLED') || 'false'
);

export const isSSOV2Enabled = () => localStorage.getItem('C1C_SSO_V2_ENABLED') === 'true';

// C1 APPS End Of Life (EOL) and End Of Sale (EOS) management
export const RETIRED_SERVICES_TABLE = {
  snyk: true,
  application: true,
  container: true,
};

// Handle special end-of-life conditions for services here.
const END_OF_LIFE_SERVICES_TABLE = {
  container: (service = {}) => {
    // NOTE: the service is removed from the list of services during the first check (L#51)
    if (!('license' in service && 'state' in service)) return true;
    return service.license === 'unlicensed' && service.state === 'endOfSale';
  },
};

/**
 * Returns an object with the key as the serviceId and the value as the service.
 * If a serviceId is provided, it returns the service object.
 *
 * @param {string} serviceId - The ID of the service to retrieve.
 */
export const getServiceDetails = (serviceId) => {
  const servicesList =
    JSON.parse(sessionStorage.getItem('C1C_Shell_State') || '{}')?.c1Services?.c1ServicesList || [];
  const servicesLicenseType = {};

  servicesList.forEach((service) => {
    servicesLicenseType[service.id] = service;
  });

  return serviceId ? servicesLicenseType[serviceId] : servicesLicenseType;
};

export const isServiceEntitled = (service) => {
  const { license, expired, id } = service || {};

  if (RETIRED_SERVICES_TABLE[id]) {
    if (license === undefined && expired === undefined) return false;
    if (license === 'unlicensed' || (license === 'trial' && expired === true)) return false;
  }

  return true;
};

// Returns true if the service is EOL based on the serviceId.
export const isServiceEndOfLife = (service) => {
  const serviceId = service.id;
  if (!RETIRED_SERVICES_TABLE?.[serviceId]) return false;

  const isEntitled = isServiceEntitled(service);

  const isEOL =
    typeof END_OF_LIFE_SERVICES_TABLE?.[serviceId] === 'function'
      ? END_OF_LIFE_SERVICES_TABLE?.[serviceId](service)
      : true;

  return !isEntitled && isEOL;
};

export const reviewServicesLicense = (services = []) => {
  const servicesList = [];
  let snykService = null;

  for (const service of services) {
    if (isServiceEndOfLife(service)) continue;

    if (service.id === 'snyk') {
      // Ensure Snyk is the last service in the list
      snykService = { ...service, retired: !!RETIRED_SERVICES_TABLE[service.id] };
    } else {
      servicesList.push({ ...service, retired: !!RETIRED_SERVICES_TABLE[service.id] });
    }
  }
  // Make sure Snyk is the last Service in the list
  if (snykService) servicesList.push(snykService);

  return servicesList;
};
