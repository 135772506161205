export const C1C_MOCK_API_PAYLOAD_TOKEN = {
  token: 'mock-token-data',
  expires: '2020-12-17T16:13:38Z',
};

export const C1C_MOCK_API_PAYLOAD_SERVICES = {
  services: [
    {
      id: 'workload',
      category: 'security',
      state: 'general-availability',
      name: 'Workload Security',
      description: 'Runtime workload and container security',
      links: [{ rel: 'endpoint', href: 'https://workload.us-1.dev-cloudone.trendmicro.com/' }],
      license: 'licensed',
    },
    {
      id: 'network',
      category: 'security',
      state: 'general-availability',
      name: 'Network Security',
      description: 'Network layer security, including IPS',
      links: [{ rel: 'endpoint', href: 'https://network.us-1.dev-cloudone.trendmicro.com/' }],
      license: 'licensed',
    },
    {
      id: 'application',
      category: 'security',
      state: 'general-availability',
      name: 'Application Security',
      description: 'Serverless, API, and application security',
      links: [{ rel: 'endpoint', href: 'https://application.us-1.dev-cloudone.trendmicro.com/' }],
      license: 'licensed',
    },
    {
      id: 'filestorage',
      category: 'security',
      state: 'general-availability',
      name: 'File Storage Security',
      description: 'File scanning for cloud storage services',
      links: [{ rel: 'endpoint', href: 'https://filestorage.us-1.dev-cloudone.trendmicro.com/' }],
      license: 'licensed',
    },
    {
      id: 'container',
      category: 'security',
      state: 'general-availability',
      name: 'Container Security',
      description: 'Image assurance and deployment control',
      links: [{ rel: 'endpoint', href: 'https://container.us-1.dev-cloudone.trendmicro.com/' }],
      license: 'licensed',
    },
    {
      id: 'conformity',
      category: 'security',
      state: 'limited-preview',
      name: 'Conformity',
      description: 'Cloud security and compliance posture management',
      links: [{ rel: 'endpoint', href: 'https://conformity.us-1.dev-cloudone.trendmicro.com/' }],
      license: 'licensed',
    },
  ],
};

// Has one of the licenseMode strings
// Note: CTA page display will base on mapping defined on https://wiki.jarvis.trendmicro.com/x/RSjDLw
// "undefined"
// "inherited"
// "trial"
// "freemium"
// "seat_subscription"
// "utility_prepaid"
// "pay_as_you_go"
// "azure_license"
// "aws_saas_metered_billing"
// "aws_saas_entitlement_billing"
// "aws_saas_metered_entitlement_billing"
// "azure_saas_metered_billing"
export const C1C_MOCK_API_PAYLOAD_ACCOUNT_DETAIL = { licenseMode: 'pay_as_you_go' };

export const C1C_MOCK_API_PAYLOAD_ACCOUNT = {
  account: [
    {
      created: '2021-04-07T21:47:47Z',
      id: '367203506990',
      lastModified: '2021-04-07T21:47:47Z',
      links: [
        {
          rel: 'token',
          href:
            'https://accounts.us-1.dev-cloudone.trendmicro.com/api/accounts/367203506990/tokens',
          method: 'post',
        },
        {
          rel: 'services',
          href: 'https://services.ca-1.dev-cloudone.trendmicro.com/api/services/',
          method: 'get',
        },
      ],
      locale: 'en',
      mfaRequired: false,
      name: 'Sample Account Inc.',
      region: 'us-1',
      state: 'active',
      timezone: 'America/Toronto',
      urn: 'urn:cloudone:identity:us-1:367203506990:account/367203506990',
    },
  ],
};

export const C1C_MOCK_API_PAYLOAD_ACCOUNTS = {
  accounts: [
    {
      id: '123456789012',
      urn: 'urn:cloudone:identity::123456789012:account',
      name: 'Example 1, Inc.',
      state: 'active',
      region: 'ca-1',
      links: [
        {
          rel: 'token',
          href:
            'https://accounts.us-1.dev-cloudone.trendmicro.com/api/accounts/123456789012/tokens',
          method: 'post',
        },
        {
          rel: 'services',
          href: 'https://services.ca-1.dev-cloudone.trendmicro.com/api/services/',
          method: 'get',
        },
      ],
    },
    {
      id: '210987654321',
      urn: 'urn:cloudone:identity::210987654321:account',
      name: 'Example 2, Inc.',
      state: 'active',
      region: 'eu-1',
      links: [
        {
          rel: 'token',
          href:
            'https://accounts.us-1.dev-cloudone.trendmicro.com/api/accounts/210987654321/tokens',
          method: 'post',
        },
        {
          rel: 'services',
          href: 'https://services.eu-1.dev-cloudone.trendmicro.com/api/services/',
          method: 'get',
        },
      ],
    },
  ],
};

export const C1C_MOCK_API_CURSOR =
  'eyJrIjoiMXByUXVIaE9BWWtMWDUxMEFuNTBtY0twS2d5IiwibiI6IlBRRnBPOW9XcWlDZmttTm4ifQ.jG2_cI0yNqOlBjMjtEGch_Y1tZdK0kSz7jr8GDHKWhheK7fcjcPmcN8LmVQ1lqawQzU_Pny8g1nAkWjZLX4Y0Fy-7Rvf3NTaeHmJ02DnZpsV65VGxrfuCKj_l-GLx7WVEcWh1RcXSLG8HmytAoBJxRZjfqU3-p2JFxlZmyEKwlDb9F7551zL5Px1GHZggthLvZFQvPYP0E5nGfqyUVSQdSZRzfdNNtfeYFxrn06HvulmMfCSRykKqV8XtmuidFJw_WR74RSib0BxEAEamoPTbg8K82B0Xp23l8zPR-OP6bWmwlVDzUr0hCKyhWbhHVojCenvrNzJSdsoAtYyB0iPzm_rqQDdfCUb2A03NjR_mi1yUHCXNc1TIg9ARNqDCz_KgkjQXBP9aRgVTB6Y8T7TjNBgCFGaIOyNSTdu8Gj-pAu7x8ueqFzNWZncj6ZHkaJBZplyJ2IlaTWzJlygenNDaLDvFSCUm4TOGrOY6UALSS0Ofls6uIq1rNwxzomM-a9jzwkJNhhnK-FEnUNYsE8rmXuWOvofWx4vk2rRdkTdgzU-0kVwr-9RYgVqFYbK-B-Nq7Y__ozhkcR9eqgpuIpVfIExxHLKluAqEFf-sLpT3E3bkd6A82ieks2h263EgPqtguvn27hrRWdtFzM1bB2g52E3KjC60hUZpxz0ETuf8wiLKwKfVelXExFCx4wZm6TsUt9_Rh6S8QDCZhVmCUXc3G_jkUdqLop3PPRAq35CByVHfs0AcF5BzjBSLxHUrDVFwOOPufpxtq5iLkTXB2FRWv4zgTZDk-pCgBbKOfK8GCpJRRySpGTWBv5tAHeb2PNlnQV7jjzp1QP0ZHMTbkXz7OBK9iofHqKcdUY3KsTxzkPFGwNQQeAv3Nf62YpguNBq_gD_DvDhS6bp9jPJADFBMvCN5ib-tiaMpF3pnmuGLPkYMKvS6HlOPksw7ClsW9NUnUB4SQznL84Q310dL_7ev6qlpL1YGX-WSf4EwkwjwXiWlaF2ZFpxu96IsI4EL6Wj8_j_J3RfyRxDLw';

export const C1C_MOCK_API_PAYLOAD_USERS = {
  users: [
    {
      id: '36819e49-23da-4de1-abd6-f819d36415c2',
      roleID: 'full-access',
      accountID: '546100445911',
      name: 'test-user-1',
      email: 'test-user-1@example.com',
      locale: 'en',
      timezone: 'America/Toronto',
      state: 'CONFIRMED',
      mfaEnabled: false,
      created: '2021-03-09T17:20:30Z',
      lastModified: '2021-03-11T17:35:19Z',
      urn: 'urn:cloudone:identity:us-1:546100445911:user/36819e49-23da-4de1-abd6-f819d36415c2',
    },
  ],
  next:
    'eyJrIjoiMXByUXVIaE9BWWtMWDUxMEFuNTBtY0twS2d5IiwibiI6IlBRRnBPOW9XcWlDZmttTm4ifQ.jG2_cI0yNqOlBjMjtEGch_Y1tZdK0kSz7jr8GDHKWhheK7fcjcPmcN8LmVQ1lqawQzU_Pny8g1nAkWjZLX4Y0Fy-7Rvf3NTaeHmJ02DnZpsV65VGxrfuCKj_l-GLx7WVEcWh1RcXSLG8HmytAoBJxRZjfqU3-p2JFxlZmyEKwlDb9F7551zL5Px1GHZggthLvZFQvPYP0E5nGfqyUVSQdSZRzfdNNtfeYFxrn06HvulmMfCSRykKqV8XtmuidFJw_WR74RSib0BxEAEamoPTbg8K82B0Xp23l8zPR-OP6bWmwlVDzUr0hCKyhWbhHVojCenvrNzJSdsoAtYyB0iPzm_rqQDdfCUb2A03NjR_mi1yUHCXNc1TIg9ARNqDCz_KgkjQXBP9aRgVTB6Y8T7TjNBgCFGaIOyNSTdu8Gj-pAu7x8ueqFzNWZncj6ZHkaJBZplyJ2IlaTWzJlygenNDaLDvFSCUm4TOGrOY6UALSS0Ofls6uIq1rNwxzomM-a9jzwkJNhhnK-FEnUNYsE8rmXuWOvofWx4vk2rRdkTdgzU-0kVwr-9RYgVqFYbK-B-Nq7Y__ozhkcR9eqgpuIpVfIExxHLKluAqEFf-sLpT3E3bkd6A82ieks2h263EgPqtguvn27hrRWdtFzM1bB2g52E3KjC60hUZpxz0ETuf8wiLKwKfVelXExFCx4wZm6TsUt9_Rh6S8QDCZhVmCUXc3G_jkUdqLop3PPRAq35CByVHfs0AcF5BzjBSLxHUrDVFwOOPufpxtq5iLkTXB2FRWv4zgTZDk-pCgBbKOfK8GCpJRRySpGTWBv5tAHeb2PNlnQV7jjzp1QP0ZHMTbkXz7OBK9iofHqKcdUY3KsTxzkPFGwNQQeAv3Nf62YpguNBq_gD_DvDhS6bp9jPJADFBMvCN5ib-tiaMpF3pnmuGLPkYMKvS6HlOPksw7ClsW9NUnUB4SQznL84Q310dL_7ev6qlpL1YGX-WSf4EwkwjwXiWlaF2ZFpxu96IsI4EL6Wj8_j_J3RfyRxDLw',
};

export const C1C_MOCK_API_PAYLOAD_USERS_2 = {
  users: [
    {
      id: '36819e49-23da-4de1-abd6-f819d36415c2',
      roleID: 'full-access',
      accountID: '546100445911',
      name: 'test-user-2',
      email: 'test-user-2@example.com',
      locale: 'en',
      timezone: 'America/Toronto',
      state: 'CONFIRMED',
      mfaEnabled: false,
      created: '2021-03-09T17:20:30Z',
      lastModified: '2021-03-11T17:35:19Z',
      urn: 'urn:cloudone:identity:us-1:546100445911:user/36819e49-23da-4de1-abd6-f819d36415c2',
    },
  ],
};

export const C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT = {
  invitations: [
    {
      id: '7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
      email: 'test-user-3@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:15Z',
      lastSent: '2021-03-11T17:37:15Z',
      created: '2021-03-11T17:37:15Z',
      lastModified: '2021-03-11T17:37:15Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
    },
    {
      id: '1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
      email: 'test-user-4@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:07Z',
      lastSent: '2021-03-11T17:37:07Z',
      created: '2021-03-11T17:37:07Z',
      lastModified: '2021-03-11T17:37:07Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-5@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'REJECTED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-6@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'EXPIRED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
      email: 'test-user-7@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:15Z',
      lastSent: '2021-03-11T17:37:15Z',
      created: '2021-03-11T17:37:15Z',
      lastModified: '2021-03-11T17:37:15Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
    },
    {
      id: '1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
      email: 'test-user-4@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:07Z',
      lastSent: '2021-03-11T17:37:07Z',
      created: '2021-03-11T17:37:07Z',
      lastModified: '2021-03-11T17:37:07Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-5@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'REJECTED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-6@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'EXPIRED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
      email: 'test-user-7@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:15Z',
      lastSent: '2021-03-11T17:37:15Z',
      created: '2021-03-11T17:37:15Z',
      lastModified: '2021-03-11T17:37:15Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
    },
    {
      id: '1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
      email: 'test-user-4@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:07Z',
      lastSent: '2021-03-11T17:37:07Z',
      created: '2021-03-11T17:37:07Z',
      lastModified: '2021-03-11T17:37:07Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-5@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'REJECTED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-6@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'EXPIRED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
  ],
  next:
    'eyJrIjoiMXByUXVIaE9BWWtMWDUxMEFuNTBtY0twS2d5IiwibiI6IlBRRnBPOW9XcWlDZmttTm4ifQ.jG2_cI0yNqOlBjMjtEGch_Y1tZdK0kSz7jr8GDHKWhheK7fcjcPmcN8LmVQ1lqawQzU_Pny8g1nAkWjZLX4Y0Fy-7Rvf3NTaeHmJ02DnZpsV65VGxrfuCKj_l-GLx7WVEcWh1RcXSLG8HmytAoBJxRZjfqU3-p2JFxlZmyEKwlDb9F7551zL5Px1GHZggthLvZFQvPYP0E5nGfqyUVSQdSZRzfdNNtfeYFxrn06HvulmMfCSRykKqV8XtmuidFJw_WR74RSib0BxEAEamoPTbg8K82B0Xp23l8zPR-OP6bWmwlVDzUr0hCKyhWbhHVojCenvrNzJSdsoAtYyB0iPzm_rqQDdfCUb2A03NjR_mi1yUHCXNc1TIg9ARNqDCz_KgkjQXBP9aRgVTB6Y8T7TjNBgCFGaIOyNSTdu8Gj-pAu7x8ueqFzNWZncj6ZHkaJBZplyJ2IlaTWzJlygenNDaLDvFSCUm4TOGrOY6UALSS0Ofls6uIq1rNwxzomM-a9jzwkJNhhnK-FEnUNYsE8rmXuWOvofWx4vk2rRdkTdgzU-0kVwr-9RYgVqFYbK-B-Nq7Y__ozhkcR9eqgpuIpVfIExxHLKluAqEFf-sLpT3E3bkd6A82ieks2h263EgPqtguvn27hrRWdtFzM1bB2g52E3KjC60hUZpxz0ETuf8wiLKwKfVelXExFCx4wZm6TsUt9_Rh6S8QDCZhVmCUXc3G_jkUdqLop3PPRAq35CByVHfs0AcF5BzjBSLxHUrDVFwOOPufpxtq5iLkTXB2FRWv4zgTZDk-pCgBbKOfK8GCpJRRySpGTWBv5tAHeb2PNlnQV7jjzp1QP0ZHMTbkXz7OBK9iofHqKcdUY3KsTxzkPFGwNQQeAv3Nf62YpguNBq_gD_DvDhS6bp9jPJADFBMvCN5ib-tiaMpF3pnmuGLPkYMKvS6HlOPksw7ClsW9NUnUB4SQznL84Q310dL_7ev6qlpL1YGX-WSf4EwkwjwXiWlaF2ZFpxu96IsI4EL6Wj8_j_J3RfyRxDLw',
};

export const C1C_MOCK_API_PAYLOAD_INVITATIONS_SENT_2 = {
  invitations: [
    {
      id: '7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
      email: 'nextTest23@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:15Z',
      lastSent: '2021-03-11T17:37:15Z',
      created: '2021-03-11T17:37:15Z',
      lastModified: '2021-03-11T17:37:15Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
    },
    {
      id: '1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
      email: 'nextTest23@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:07Z',
      lastSent: '2021-03-11T17:37:07Z',
      created: '2021-03-11T17:37:07Z',
      lastModified: '2021-03-11T17:37:07Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'next23@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'REJECTED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-6@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'EXPIRED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
      email: 'nextTest23@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:15Z',
      lastSent: '2021-03-11T17:37:15Z',
      created: '2021-03-11T17:37:15Z',
      lastModified: '2021-03-11T17:37:15Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
    },
    {
      id: '1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
      email: 'nextTest23@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:07Z',
      lastSent: '2021-03-11T17:37:07Z',
      created: '2021-03-11T17:37:07Z',
      lastModified: '2021-03-11T17:37:07Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'next23@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'REJECTED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-6@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'EXPIRED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
      email: 'nextTest23@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:15Z',
      lastSent: '2021-03-11T17:37:15Z',
      created: '2021-03-11T17:37:15Z',
      lastModified: '2021-03-11T17:37:15Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/7e971aa1f8281bf66cabede51161d21be4b715865626664a1aa75e6551404537',
    },
    {
      id: '1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
      email: 'nextTest23@example.com',
      accountID: '546100445911',
      roleID: 'full-access',
      state: 'INVITED',
      expiry: '2021-04-10T17:37:07Z',
      lastSent: '2021-03-11T17:37:07Z',
      created: '2021-03-11T17:37:07Z',
      lastModified: '2021-03-11T17:37:07Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/1a6608710e9fa21cb4745b33742d4dcf58354f9b849e492f23c259ec4925c55d',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'next23@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'REJECTED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
    {
      id: '776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
      email: 'test-user-6@example.com',
      accountID: '546100445911',
      roleID: 'read-only',
      state: 'EXPIRED',
      expiry: '2021-04-15T22:45:47Z',
      lastSent: '2020-06-17T20:34:58Z',
      created: '2021-03-16T22:45:47Z',
      lastModified: '2021-03-17T00:01:12Z',
      urn:
        'urn:cloudone:identity:us-1:546100445911:invitation/776e85ff2d2a4052cf2fc79185866e7cd6f3166aa4b45acab40f73eff5b76eba',
    },
  ],
};

export const C1C_MOCK_API_PAYLOAD_ROLES = {
  roles: [
    {
      id: 'full-access',
      name: 'Full Access',
      description: 'This role gives full access to all Cloud One services',
      servicePolicies: [
        'urn:cloudone:accounts:us-1:546100445911:role/full-access',
        'urn:cloudone:application:us-1:546100445911:role/full-access',
        'urn:cloudone:billing:us-1:546100445911:role/full-access',
        'urn:cloudone:conformity:us-1:546100445911:role/full-access',
        'urn:cloudone:container:us-1:546100445911:role/full-access',
        'urn:cloudone:file:us-1:546100445911:role/full-access',
        'urn:cloudone:identity:us-1:546100445911:role/full-access',
        'urn:cloudone:network:us-1:546100445911:role/full-access',
        'urn:cloudone:workload:us-1:546100445911:role/full-access',
      ],
      created: '2021-01-28T00:00:00Z',
      lastModified: '2021-01-28T00:00:00Z',
      urn: 'urn:cloudone:identity:us-1:546100445911:role/full-access',
    },
    {
      id: 'read-only',
      name: 'Read Only',
      description: 'This role gives read only access to all Cloud One services',
      servicePolicies: [
        'urn:cloudone:accounts:us-1:546100445911:role/read-only',
        'urn:cloudone:application:us-1:546100445911:role/read-only',
        'urn:cloudone:billing:us-1:546100445911:role/read-only',
        'urn:cloudone:conformity:us-1:546100445911:role/read-only',
        'urn:cloudone:container:us-1:546100445911:role/read-only',
        'urn:cloudone:file:us-1:546100445911:role/read-only',
        'urn:cloudone:identity:us-1:546100445911:role/read-only',
        'urn:cloudone:network:us-1:546100445911:role/read-only',
        'urn:cloudone:workload:us-1:546100445911:role/read-only',
      ],
      created: '2021-01-28T00:00:00Z',
      lastModified: '2021-01-28T00:00:00Z',
      urn: 'urn:cloudone:identity:us-1:546100445911:role/read-only',
    },
  ],
};
