/**
 * This module patches the /api/accounts/:id endpoint
 * by adding the migration state to the returned data
 */
import { rest } from 'msw';
import accountsFixtures from '../fixtures/accounts-data.json';

// Requests the actual API Data
const getAPIResponseData = async (req, ctx) => {
  const apiResponse = await ctx.fetch(req);
  return apiResponse.json();
};

// Patches the API response with the services update state.
const getAccountMigrationState = () => {
  const c1wsMigrationState = localStorage.getItem('C1C_C1WS_UPDATE_STATE');
  if (!['migrated', 'migrating', 'failed'].includes(c1wsMigrationState)) {
    return {};
  }

  return accountsFixtures[c1wsMigrationState];
};

const describeAccount = rest.get('**/api/accounts/:id', async (req, res, ctx) => {
  const apiResponseData = await getAPIResponseData(req, ctx);

  return res(ctx.status(200), ctx.json({ ...apiResponseData, ...getAccountMigrationState() }));
});

export default [describeAccount];
